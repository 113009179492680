<script>
export default {
  props: ["isShowseparator"],
  methods: {
    checkShowSeparetor() {
      if (
        this.$props.isShowseparator == undefined ||
        this.$props.isShowseparator == null
      ) {
        return true;
      } else {
        return this.$props.isShowseparator;
      }
    },
  },
  render() {
    return (
      <b-container>
        <b-row class="d-flex flex-column">
          <div class="mr-auto w-100">{this.$slots.leftAction}</div>
          <div
            v-show={this.checkShowSeparetor()}
            class="separator-line-total my-4"
          ></div>
          <div class="margin-content">{this.$slots.leftInfo}</div>
        </b-row>
        <b-row class="header-content margin-content">{this.$slots.headerContent}</b-row>
        <b-container class="margin-content">{this.$slots.content}</b-container>
        <b-row class="footer-margin">{this.$slots.footerContent}</b-row>
      </b-container>
    );
  },
};
</script>

<style lang="scss" scoped>
.footer-margin {
  margin-top: 4rem;
  justify-content: center;
  align-content: center;
}
.margin-content {
  margin: auto !important;
  width: 97%;
}
</style>
