<template>
  <div>
    <CustomModalTemplate
      modalId="communication-modal"
      ref="communication-modal"
      class="communication-modal"
      noCloseOnBackdrop
    >
      <template slot="header">
        <div
          class="header-communication color-green-2"
          v-if="this.communication"
        >
          {{ communication.title }} <strong>{{ getUser() }}</strong
          >!
        </div>
        <div class="second--image-div">
          <img
            class="second--image"
            src="../../../assets/images/unimed-robozinho.svg"
          />
        </div>
        <div @click="this.onCloseModalHandler" class="icon--close">
          <img src="../../../assets/icons/close-circle-outline-green.svg" />
        </div>
      </template>
      <template slot="content">
        <div
          class="content-principal"
          @scroll="onScroll"
          v-if="this.communication"
        >
          <p
            class="color-green-2 font-communication-size"
            v-html="this.getCommunicationDescription()"
          ></p>
        </div>
      </template>
      <template slot="footer">
        <div class="footer-div-container">
          <div class="line-footer"></div>
          <div class="footer-div-button">
            <b-button
              class="ml-4"
              variant="outline-primary"
              :disabled="buttonDisabled"
              @click="this.onUpdate"
            >
              {{ this.getCommunicationTextButton() }}
            </b-button>
            <b-button class="ml-4" variant="primary" @click="this.onRedirect">
              {{ this.getCommunicationTextLink() }}
            </b-button>
          </div>
        </div>
      </template>
    </CustomModalTemplate>
    <UpdatedInformations v-if="showUpdatedInformations" />
  </div>
</template>

<script>
import CustomModalTemplate from "../../../components/custom-modal/CustomModalTemplate.vue";
import {
  updateCommunication,
  getCommunication,
} from "../../../services/communication/communication";
import { getNameUser } from "../../../utils/user/user";
import isAbleRole from "../../../utils/permissions/is-able-role";
import { RoleList } from "../../../static-data/RoleList";
import UpdatedInformations from "../../../modules/updatedInformations/views/UpdatedInformations.vue";
import { Firebase } from "../../../services/firebase/log-firebase";

export default {
  components: {
    CustomModalTemplate,
    UpdatedInformations,
  },
  data() {
    return {
      communication: {},
      buttonDisabled: true,
      firebase: null,
      showUpdatedInformations: false,
    };
  },
  async mounted() {
    await this.checkVisibility();
    this.initLog();
  },
  computed: {
    showIfStoreChange() {
      return this.$store.getters.getUserInformation?.nickname;
    },
  },
  watch: {
    showIfStoreChange() {
      this.checkVisibility();
    },
  },

  methods: {
    initLog() {
      this.firebase = new Firebase();
    },
    onScroll(el) {
      if (
        Math.ceil(el.srcElement.offsetHeight + el.srcElement.scrollTop) >=
        el.srcElement.scrollHeight
      ) {
        this.buttonDisabled = false;
      }
    },
    getCommunicationDescription() {
      return this.communication ? this.communication.description : "";
    },
    getCommunicationTextLink() {
      return this.communication ? this.communication.textLink : "";
    },
    getCommunicationTextButton() {
      return this.communication ? this.communication.textButton : "";
    },

    onRedirect() {
      const pathIfAdmin = (path) =>
        this.$store.getters.isAdmin ? "/admin" + path : path;
      this.onCloseModalHandler();
      const isValidUrl = (urlString) => {
        try {
          return Boolean(new URL(urlString));
        } catch (e) {
          return false;
        }
      };
      if (this.firebase !== null) {
        this.firebase.addInfoLog(
          "- Clicou em '" +
            this.getCommunicationTextLink() +
            "'." +
            "'. Comunicado: " +
            this.communication.id,
          "COMUNICADO"
        );
      }

      if (isValidUrl(this.communication.linkToRedirect)) {
        window.open(this.communication.linkToRedirect, "_blank");
      } else {
        this.$router.push(pathIfAdmin(this.communication.linkToRedirect));
      }
    },
    onUpdate() {
      this.onCloseModalHandler();
      if (!isAbleRole(RoleList.AUTHORITY_ADMIN)) {
        updateCommunication(this.communication.id);
        if (this.firebase !== null) {
          this.firebase.addInfoLog(
            "- Clicou em '" +
              this.getCommunicationTextButton() +
              "'. Comunicado: " +
              this.communication.id,
            "COMUNICADO"
          );
        }
      }
    },
    getUser() {
      return getNameUser();
    },
    onCloseModalHandler() {
      this.$refs["communication-modal"].$children[0].hide();
    },
    async checkVisibility() {
      if (isAbleRole(RoleList.AUTHORITY_USER_IS_CUSTOMER)) {
        let communications = await getCommunication();
        communications.sort(
          (a, b) => a.apresentationSequence > b.apresentationSequence
        );
        if (communications.length > 0) {
          this.communication = communications[0];
          if (this.communication.description.length < 450)
            this.buttonDisabled = false;
          this.$refs["communication-modal"].$children[0].show();
          this.showUpdatedInformations = false;
        } else {
          this.showUpdatedInformations = true;
        }
      } else {
        this.showUpdatedInformations = true;
      }
    },
    onChangeTabHandler() {},
  },
};
</script>

<style lang="scss" scoped>
p {
  color: black;
}
.content-principal {
  overflow-y: scroll;
  height: 20rem;
  margin-top: 0;
  display: flex;
  width: 70%;
  padding: 0rem 2rem 2rem;
}
.second--image-div {
  position: absolute;
  top: 0;
  right: 0;
  margin: 7px 2rem 0px 0px;
}
.icon--close {
  cursor: pointer;
  @extend .second--image-div;
  margin: 18px 2rem 0px 0px;
}

@media only screen and (max-width: 800px) {
  .content-principal {
    width: 100%;
  }
  .second--image {
    width: 60%;
    float: right;
  }
}
.footer-div-container {
  position: relative;
  width: 100%;
  padding: 0rem 0rem 0rem;
}
.footer-div-button {
  position: relative;
  margin-top: 2rem;
  display: flex;
  padding: 2rem;
  justify-content: flex-end;
}
.line-footer {
  position: absolute;
  width: 100%;
  height: 8px;
  background: linear-gradient(to bottom, transparent 0%, #0000000a 80%);
}
.font-communication-size {
  font-size: 16px;
}
.header-communication {
  font-size: 16px;
  padding: 0rem 0.5rem 0rem;
}
</style>

<style lang="scss">
#communication-modal .modal-content {
  padding: 0rem !important;
}

#communication-modal footer.modal-footer {
  padding: 0rem !important;
}
#communication-modal .footer-div-button {
  padding: 1rem;
}
#communication-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
}
@media only screen and (max-width: 800px) {
  #communication-modal .modal-dialog {
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-top: 40% !important;
  }
  #communication-modal .modal-content {
    margin-right: 36px;
  }
}
.font-communication-size a {
  font-size: 1.6rem;
  color: var(--green-2);
  text-decoration: underline;
}
</style>
