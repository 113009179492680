import { isMobile } from "../../utils/mobile/mobile";
import FirebaseClient from "./db-firebase";
import { addDoc, collection, serverTimestamp} from "firebase/firestore/lite";
import initializeJsonFromLocalStorage from "../../utils/storage/initialize-storage-utils";
import store from "../../store/index";

function addMethod(object, name, fn) {
  var old = object[name];
  object[name] = function() {
    if (fn.length == arguments.length) return fn.apply(this, arguments);
    else if (typeof old == "function") return old.apply(this, arguments);
  };
}

export function Firebase(nameCollection) {
  const name =
    nameCollection === null || nameCollection === undefined
      ? "logActivities"
      : nameCollection;

  const db = FirebaseClient.getInstance();
  const collectionLogs = collection(db, name);

  function addLog(route, isError, type) {
    try {
      const mobile = isMobile();
     let objToSend =  {
        isAdmin: store.getters.isAdmin,
        info: `acessou ${route}`,
        isMobile: mobile ? true : false,
        isApp: initializeJsonFromLocalStorage("isApp"),
        time: serverTimestamp(),
        isError: isError,
        type:type
      }
      if (store.getters.getTokenAdmin) {
        objToSend = {
          ...objToSend,
          crm: store.getters.getUserNameAdmin,
          crmSimulado: store.getters.getUserName
        }
      } else { 
        objToSend = {
          ...objToSend,
          crm: store.getters.getUserName
        }
      }
      addDoc(collectionLogs, objToSend).then(() => {});
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  addMethod(this, "addInfoLog", function(route, type) {
    addLog(route, false, type)
  });

  addMethod(this, "addErrorLog", function(route) {
    addLog(route, true, 'ERROR')
  });
}
